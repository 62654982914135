import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/fonts/Feather/feather.css';
import './assets/fonts/font-awesome-4.7.0/css/font-awesome.css';
import './../node_modules/flickity/dist/flickity.min.css';
import './../node_modules/flickity-fade/flickity-fade.css';
import './../node_modules/aos/dist/aos.css';
import './../node_modules/jarallax/dist/jarallax.css';
import './../node_modules/highlightjs/styles/vs2015.css';
import './../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css';
import './assets/css/theme.css';


import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
