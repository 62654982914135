import React from 'react';
import Header from '../Layout/Header/Header';
import Footer from '../Layout/Footer/Footer';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const getDetails = () => {
	const handleDownload = () => {};
	return (
		<div>
			<section className="pt-8 pt-md-11 pb-8 pb-md-14">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-12 col-md">
							<h1 className="display-4 mb-0">Annual Return Form</h1>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<hr className="my-6 my-md-8" />
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-11 ">
							<h3 className="mb-5">Disclosures as per Companies Act 2013:</h3>
							<div className="d-flex">
								<span className="badge badge-rounded-circle badge-danger-soft mt-0 mr-4">
									<i className="fa fa-file-pdf-o"></i>
								</span>

								<Link
									to="/pdf/CTPL_Form_MGT_7A_FY 2022-23.pdf"
									target="_blank"
									className="text-gray-800"
									download
								>
									Annual Return - FORM NO.MGT-7 (FY-22-23)
								</Link>
							</div>
							<div className="d-flex">
								<span className="badge badge-rounded-circle badge-danger-soft mt-0 mr-4">
									<i className="fa fa-file-pdf-o"></i>
								</span>

								<Link
									to="/pdf/CTPL_Form_MGT_7A_FY 2021-22.pdf"
									target="_blank"
									className="text-gray-800"
									download
								>
									Annual Return - FORM NO.MGT-7 (FY-21-22)
								</Link>
							</div>
							<div className="d-flex">
								<span className="badge badge-rounded-circle badge-danger-soft mt-0 mr-4">
									<i className="fa fa-file-pdf-o"></i>
								</span>

								<Link
									to="/pdf/Form MGT-7A - 2020-21.pdf"
									target="_blank"
									className="text-gray-800"
									download
								>
									Annual Return - FORM NO.MGT-7 (FY-20-21)
								</Link>
							</div>
							<div className="d-flex">
								<span className="badge badge-rounded-circle badge-danger-soft mt-0 mr-4">
									<i className="fa fa-file-pdf-o"></i>
								</span>

								<Link
									to="/pdf/Form MGT-7 - 2019-20.pdf"
									target="_blank"
									className="text-gray-800"
									download
								>
									Annual Return - FORM NO.MGT-7 (FY-19-20)
								</Link>
							</div>
							<div className="d-flex">
								<span className="badge badge-rounded-circle badge-danger-soft mt-0 mr-4">
									<i className="fa fa-file-pdf-o"></i>
								</span>

								<Link
									to="/pdf/Form MGT-7 - 2018-19.pdf"
									target="_blank"
									className="text-gray-800"
									download
								>
									Annual Return - FORM NO.MGT-7 (FY-18-19)
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

const Returns = () => {
	return (
		<>
			<Header></Header>
			{getDetails()}
			<Footer></Footer>
		</>
	);
};

export default Returns;
