import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Home from './Components/Home/Home';
import AboutUs from './Components/AboutUs/AboutUs';
import Termscondition from './Components/Layout/Navigation/Termscondition/Termscondition';
import ContactUs from './Components/Layout/Navigation/ContactUs/ContactUs';
import PartnerWithUs from './Components/Layout/Navigation/PartnerWithUs/PartnerWithUs';
import FinApp from './Components/FinvuProducts/FinApp';
// import Finsense from './Components/FinvuProducts/Finsense';
// import FinConnectHub from './Components/FinvuProducts/FinConnectHub';
import PageSpinner from './Components/Layout/PageSpinner/PageSpinner';
import Learn from './Components/FinvuResources/Learn';
import Security from './Components/FinvuResources/Security';
import Blog from './Components/FinvuResources/Blog';
import Lendings from './Components/FinvuSolutions/Lendings';
import PersonalFinance from './Components/FinvuSolutions/PersonalFinance';
import SavingInvestment from './Components/FinvuSolutions/SavingInvestment';
import Privacy from './Components/Layout/Navigation/Privacy/Privacy';
import Solutions from './Components/FinvuSolutions/Solutions';
import HowItWorks from './Components/FinvuHowItWorks/HowItWorks';
import Products from './Components/FinvuProducts/Products';
import Grievance from './Components/Layout/Navigation/Grievance/Grievance';
import Helpdesk from './Components/Layout/Navigation/Helpdesk/Helpdesk';
import Pricing from './Components/Layout/Navigation/Pricing/Pricing';
import CitizenCharter from './Components/Layout/Navigation/CitizenCharter/CitizenCharter';
import Returns from './Components/FinvuResources/Returns';
import NodalOfficer from './Components/Layout/Navigation/NodalOfficer/NodalOfficer';

function App() {
	return (
		<Router basename="/" onUpdate={() => window.scrollTo(0, 0)}>
			<React.Suspense fallback={<PageSpinner />}>
				<Switch>
					<Route
						exact
						path="/aboutus"
						render={(props) => <AboutUs {...props} />}
					></Route>
					<Route
						exact
						path="/terms"
						render={(props) => <Termscondition {...props} />}
					></Route>
					<Route
						exact
						path="/contactus"
						render={(props) => (
							<ContactUs {...props} displayMobile heading="Contact Us" />
						)}
					></Route>
					{/* <Route
						exact
						path="/partnerwithus"
						render={(props) => (
							<PartnerWithUs {...props} heading="Partner With Us" />
						)}
					></Route> */}
					<Route
						exact
						path="/finapp"
						render={(props) => <FinApp {...props} />}
					></Route>
					{/* <Route
						exact
						path="/finsense"
						render={(props) => <Finsense {...props} />}
					></Route>
					<Route
						exact
						path="/finconnecthub"
						render={(props) => <FinConnectHub {...props} />}
					></Route> */}
					<Route
						exact
						path="/learn"
						render={(props) => <Learn {...props} />}
					></Route>
					<Route
						exact
						path="/security"
						render={(props) => <Security {...props} />}
					></Route>
					<Route
						exact
						path="/returns"
						render={(props) => <Returns {...props} />}
					></Route>
					<Route
						exact
						path="/blog"
						render={(props) => <Blog {...props} />}
					></Route>
					<Route
						exact
						path="/lendings"
						render={(props) => <Lendings {...props} />}
					></Route>
					<Route
						exact
						path="/personalfinance"
						render={(props) => <PersonalFinance {...props} />}
					></Route>
					<Route
						exact
						path="/savinginvestment"
						render={(props) => <SavingInvestment {...props} />}
					></Route>
					<Route
						exact
						path="/solutions"
						render={(props) => <Solutions {...props} />}
					></Route>
					<Route
						exact
						path="/privacy"
						render={(props) => <Privacy {...props} />}
					></Route>
					<Route
						exact
						path="/howitworks"
						render={(props) => <HowItWorks {...props} />}
					></Route>
					<Route
						exact
						path="/products"
						render={(props) => <Products {...props} />}
					></Route>
					<Route
						exact
						path="/helpdesk"
						render={(props) => (
							<Helpdesk {...props} displayMobile heading="Helpdesk" />
						)}
					></Route>
					<Route
						exact
						path="/grievance"
						render={(props) => (
							<Grievance {...props} displayMobile heading="Grievance" />
						)}
					></Route>
					<Route
						exact
						path="/nodal-officer"
						render={(props) => (
							<NodalOfficer {...props} displayMobile heading="Nodal Officer" />
						)}
					></Route>
					<Route
						exact
						path="/pricing"
						render={(props) => (
							<Pricing {...props} displayMobile heading="Pricing" />
						)}
					></Route>
					<Route
						exact
						path="/charter"
						render={(props) => <CitizenCharter {...props} />}
					></Route>
					<Route path="/" render={(props) => <Home {...props} />}></Route>
				</Switch>
			</React.Suspense>
		</Router>
	);
}

export default App;
