import React, { useEffect } from "react";
import ContactInfo from "../ContactInfo/ContactInfo";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import image1 from "./../../../../assets/img/finvu-image/contactus/05.svg";

const imagePlaceholder = [image1,""];

const Helpdesk = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props]);

  return (
    <>
      <Header />
      <div className="container d-flex flex-column">
        <div className="row align-items-center justify-content-between no-gutters min-vh-100">
          <div className="col-12 col-md-6 align-self-stretch">
          <img
                src={imagePlaceholder[0]}
                className="img-thumbnail mw-md-10 mw-lg-10 mb-3 mb-md-0 border-0"
                alt="..."
            />
          </div>

          <div className="col-12 col-md-5 py-1 py-md-1 h-75 mt-0 ml-1">

          <hr className="hr-sm my-6 my-md-8 border-white"></hr>
          <ContactInfo heading="Contact for Support" />
            <div className="row col-md-12 col-sm-12 justify-content-around">
              <p className="font-size-inherit text-gray-800">
                Please email us with details of the issue. We will aim to resolve at the earliest.
              </p>
            </div>
            <p className="font-size-inherit text-gray-800">
              <b>Email id:</b> support@cookiejar.co.in
              </p>


          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Helpdesk;
