import React from "react";

const Lendings = () => {
  const getDetails = () => {
    return (
      <section className="mt-10">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <h2>Lending</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-11">
              <p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
                More accurate, faster and tailored lending decisions can be provided for customers, with access to consented customer financial
                information directly from financial institutions. 
              </p>
              <p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
                Lenders will get a holistic view of customer finances with multiple data points, thereby deepening and strenghtening their underwriting process.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr className="my-6 my-md-8" />
          </div>
        </div>
      </section>
    );
  };

  return <>{getDetails()}</>;
};

export default Lendings;
