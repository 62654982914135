import React, { useEffect } from 'react';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import { Link } from 'react-router-dom';

const Grievance = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const getDetails = () => {
		return (
			<section className="pt-8 pt-md-11 pb-8 pb-md-14">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-12 col-md">
							<h2 className="display-6 mb-1">
								<span className="text-primary">Grievance Redressal</span>
							</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<hr className="my-6 my-md-8" />
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-11">
							<p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
								Our Grievance redressal policy is in terms of the RBI Master
								Directions. It has been framed to resolve customer grievances,
								including grievances with respect to the outsourced services, in
								a timely and efficient manner, while treating our customers
								fairly and courteously. In circumstances where, owing to various
								human, network or system errors, some issues arise that require
								a more focused and customised redressal, You may refer to the
								following redressal mechanism in the sequential order.
							</p>
							<h3 className="mb-5">Grievance Process</h3>
							<p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
								We have formulated a grievance process and an escalation matrix
								to ensure that Your concerns are routed and addressed
								appropriately. You are requested to read and understand the
								below escalation matrix to ensure a timely redressal of Your
								concern.
							</p>
							<p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
								We recommend you to go through our{' '}
								<Link className="ml-2" to="/helpdesk">
									Support/Helpdesk
								</Link>{' '}
								to address your issues, in the event that support is not able to
								address your concerns you can follow the escalation matrix as
								outlined below.
							</p>
							<p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
								We have appointed a Grievance Redressal Officer, who may be
								approached by Customers for lodging complaints/grievances.
							</p>
							<p className="font-size-inherit text-gray-800 mb-2 mb-md-2">
								<b>Grievance Redressal Officer:</b>
							</p>
							<p className="font-size-inherit text-gray-800 mb-2 mb-md-2">
								Mr Manoj Alandkar
							</p>
							<p className="font-size-inherit text-gray-800 mb-2 mb-md-2">
								<b>Email id:</b> grievance@cookiejar.co.in
							</p>
							<p className="font-size-inherit text-gray-800 mb-2 mb-md-2">
								<b>Mobile No:</b> 7030608902
							</p>
							<p className="font-size-inherit text-gray-800 mb-2 mb-md-2">
								<b>Registered Address:</b>
							</p>
							<p className="font-size-inherit text-gray-800 mb-6 mb-md-6">
								Cookiejar Technologies Pvt Ltd (Finvu), Kumar Vastu F-801
								Sr.No-209/1a, Bhamburda, Near Bhosale Nagar, Range Hills, Pune,
								Maharashtra, India, 411020
							</p>
							<p className="font-size-inherit text-gray-800 mb-6 mb-md-6">
								Grievances may be filed in respect of our services by sending an
								email to support@cookiejar.co.in or writing to our registered
								address as above.
							</p>
							<p className="font-size-inherit text-gray-800 mb-8 mb-md-8">
								<b>Please use below format for raising a complaint/grievance</b>
							</p>
							<p className="font-size-inherit text-gray-800 mb-2 mb-md-2">
								<b>FROM</b>
							</p>
							<p className="font-size-inherit text-gray-800 mb-2 mb-md-2">
								Name of the Customer :
							</p>
							<p className="font-size-inherit text-gray-800 mb-2 mb-md-2">
								Contact No :
							</p>
							<p className="font-size-inherit text-gray-800 mb-6 mb-md-6">
								Email id :
							</p>
							<p className="font-size-inherit text-gray-800 mb-2 mb-md-2">
								<b>TO</b>
							</p>
							<p className="font-size-inherit text-gray-800 mb-2 mb-md-2">
								Grievance Redressal Officer
							</p>
							<p className="font-size-inherit text-gray-800 mb-2 mb-md-2">
								Cookiejar Technologies Pvt Ltd (Finvu)
							</p>
							<p className="font-size-inherit text-gray-800 mb-2 mb-md-2">
								2nd Floor,
							</p>
							<p className="font-size-inherit text-gray-800 mb-2 mb-md-2">
								Sampada Building, Senapati Bapat Road,
							</p>
							<p className="font-size-inherit text-gray-800 mb-8 mb-md-8">
								Pune, Maharashtra, India, 411016
							</p>
							<p className="font-size-inherit text-gray-800 mb-4 mb-md-4">
								Date of event :
							</p>
							<p className="font-size-inherit text-gray-800 mb-4 mb-md-4">
								Customer Finvu AA (Account Aggregator) id :
							</p>
							<p className="font-size-inherit text-gray-800 mb-4 mb-md-4">
								Mobile No registered with Finvu :
							</p>
							<p className="font-size-inherit text-gray-800 mb-4 mb-md-4">
								Details of the Grievance / Complaint : __________________
							</p>
							<p className="font-size-inherit text-gray-800 mb-8 mb-md-8">
								_________________________________________________________
							</p>
							<p className="font-size-inherit text-gray-800 mb-10 mb-md-10"></p>
							<p className="font-size-inherit text-gray-800 mb-4 mb-md-4">
								Customer Signature
							</p>
							<p className="font-size-inherit text-gray-800 mb-12 mb-md-12">
								Date:
							</p>
							<h3 className="mb-5">Escalation Matrix for Grievances</h3>
							<table class="table">
								<thead>
									<tr>
										<th scope="col">Escalation</th>
										<th scope="col">Timeline</th>
										<th scope="col">Customer Action</th>
										<th scope="col">Company Action</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th scope="row">Level 1</th>
										<td>Day 0 (D) day of filing the grievance/complaint</td>
										<td>
											Customer sends an email to grievance@cookiejar.co.in with
											the required details
										</td>
										<td>
											Company will send (auto or manual) acknowledgement within
											24 hrs. We will aim to resolve the issues within 10 days.
										</td>
									</tr>
									<tr>
										<th scope="row">Level 2</th>
										<td>After D + 10 days </td>
										<td>
											If complaint or grievance is not resolved, then Customer
											can call the Company no 7030608902 with appropriate
											details.
										</td>
										<td>
											Company will ensure that the complaint/grievance is
											resolved within 20 days, subject to receipt of proper
											information from the customer. Any delay will be
											communicated to the customer
										</td>
									</tr>
									<tr>
										<th scope="row">Level 3</th>
										<td>After D + 30 days</td>
										<td>
											The Customer may appeal to The Reserve Bank of India (RBI)
											if the grievance/complaint is not resolved within 30 days.{' '}
										</td>
										<td></td>
									</tr>
								</tbody>
							</table>
							<b>Note:</b> In terms of the RBI Master Directions, if the
							complaint/grievance is not resolved within a period of one month
							from reporting, You may appeal to the to the Officer-in-Charge of
							the Regional Office of DNBS of Reserve Bank of India (RBI), under
							whose jurisdiction the registered office of the Company falls, the
							details of which are mentioned below-
						</div>
						<table
							style={{
								margin: '1rem auto',
								border: '1px solid black',
								borderCollapse: 'collapse',
								padding: '10px',
							}}
						>
							<tbody>
								<tr>
									<td
										colSpan="2"
										style={{
											textAlign: 'center',
											border: '1px solid black',
											padding: '8px',
										}}
									>
										<strong>Mumbai (Regional Office)</strong>
									</td>
								</tr>
								<tr>
									<td style={{ border: '1px solid black', padding: '8px' }}>
										<strong>Jurisdiction</strong>
									</td>
									<td
										style={{
											border: '1px solid black',
											padding: '8px',
										}}
									>
										State of Maharashtra and State of Goa
									</td>
								</tr>
								<tr>
									<td style={{ border: '1px solid black', padding: '8px' }}>
										<strong>Address</strong>
									</td>
									<td
										style={{
											border: '1px solid black',
											padding: '8px',
										}}
									>
										3rd Floor, RBI Building, Opp. Mumbai Central Railway
										Station, Near Maratha Mandir, Byculla, Mumbai – 400 008
									</td>
								</tr>
								<tr>
									<td style={{ border: '1px solid black', padding: '8px' }}>
										<strong>Telephone</strong>
									</td>
									<td
										style={{
											border: '1px solid black',
											padding: '8px',
										}}
									>
										022-2308 4121/2302 8436
									</td>
								</tr>
								<tr>
									<td style={{ border: '1px solid black', padding: '8px' }}>
										<strong>Fax</strong>
									</td>
									<td
										style={{
											border: '1px solid black',
											padding: '8px',
										}}
									>
										022-2302 2011
									</td>
								</tr>
								<tr>
									<td style={{ border: '1px solid black', padding: '8px' }}>
										<strong>E-mail</strong>
									</td>
									<td
										style={{
											border: '1px solid black',
											padding: '8px',
										}}
									>
										<a href="mailto:dnbsmro@rbi.org.in">dnbsmro@rbi.org.in</a>
									</td>
								</tr>
							</tbody>
						</table>
						<table
							style={{
								margin: '1rem auto',
								border: '1px solid black',
								borderCollapse: 'collapse',
								padding: '10px',
								width: '100%',
							}}
						>
							<tbody>
								<tr>
									<td
										colSpan="2"
										style={{
											textAlign: 'center',
											border: '1px solid black',
											padding: '8px',
										}}
									>
										<strong>Mumbai (Central Office)</strong>
									</td>
								</tr>
								<tr>
									<td style={{ border: '1px solid black', padding: '8px' }}>
										<strong>Name</strong>
									</td>
									<td
										style={{
											border: '1px solid black',
											padding: '8px',
										}}
									>
										Shri Sathyan David
										<br />
										Chief General Manager <br />
										Reserve Bank of India
									</td>
								</tr>
								<tr>
									<td style={{ border: '1px solid black', padding: '8px' }}>
										<strong>Address</strong>
									</td>
									<td
										style={{
											border: '1px solid black',
											padding: '8px',
										}}
									>
										Department of Non-Banking Supervision, <br />
										​Central Office,​ <br />
										World Trade Centre, Centre 1, 2nd Floor,​ <br />
										Cuffe Parade, Colaba,​ <br />
										Mumbai- 400005
									</td>
								</tr>
								<tr>
									<td style={{ border: '1px solid black', padding: '8px' }}>
										<strong>
											Telephone <br /> Fax <br /> E-mail
										</strong>
									</td>
									<td
										style={{
											border: '1px solid black',
											padding: '8px',
										}}
									>
										Phone : 022- 2215 3350 <br />
										Fax 022- 2216 2768 <br />
										cgmincdnbsco@rbi.org.in
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</section>
		);
	};

	return (
		<>
			<Header></Header>
			{getDetails()}
			<Footer></Footer>
		</>
	);
};

export default Grievance;
