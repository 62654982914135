import React, { useEffect } from 'react';
import ContactInfo from '../ContactInfo/ContactInfo';
import ContactForm from '../ContactForm/ContactForm';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';

const ContactUs = (props) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [props]);

	return (
		<>
			<Header />
			<div className="container d-flex flex-column">
				<div className="row align-items-center justify-content-between no-gutters min-vh-100">
					<div className="col-12 col-md-12 align-self-stretch">
						<ContactInfo heading="Contact Information" />
						<div className="row col-md-6 col-sm-12 justify-content-around">
							<p className="font-size-inherit text-gray-800">
								Do reach out to us for information on our products or for any
								queries.
							</p>
							<p className="font-size-inherit text-gray-800">
								We are happy to take feedback from our customers to improvise
								our products and services.
							</p>
						</div>
						<div>
							{/* <div className="row ml-0 mt-4">
								<div className="col-md-2 col-sm-4 justify-content-around">
									<span>
										<i
											className="fa fa-map-marker fa-2x"
											style={{ color: '#74B570' }}
										></i>
									</span>
								</div>
								<div className="col-md-10 col-sm-8 justify-content-around">
									<span>Contact Address</span>
									<p className="mb-0 font-size-sm text-gray-800">
										2nd Floor, Sampada Building, SB Road,
									</p>
									<p className="mt-0 font-size-sm text-gray-800">
										Pune, India, 411016
									</p>
								</div>
							</div> */}
							<div className="row ml-0 mt-4">
  
  <div className="col-md-6 d-flex align-items-center">
    <i className="fa fa-envelope fa-2x" style={{ color: '#13397F' }}></i>
    <div className="ml-3">
      <span>For Sales Enquiries</span>
      <p className="mb-0 font-size-sm text-gray-800">info@cookiejar.co.in</p>
    </div>
  </div>


  <div className="col-md-6 d-flex align-items-center mt-4 mt-md-0">
    <i className="fa fa-envelope fa-2x" style={{ color: '#13397F' }}></i>
    <div className="ml-3">
      <span>For Help and Support</span>
      <p className="mb-0 font-size-sm text-gray-800">support@cookiejar.co.in</p>
    </div>
  </div>
</div>

							{/* <div className="row ml-0 mt-8 mb-4">
								<div className="col-md-2 col-sm-4 justify-content-around">
									<span>
										<i
											className="fa fa-phone fa-2x"
											style={{ color: '#0099CC' }}
										></i>
									</span>
								</div>
								<div className="col-md-10 col-sm-8 justify-content-around">
									<span>Call Us Today</span>
									<p className="mb-0 font-size-sm text-gray-800">
										+91 70306 08902
									</p>
								</div>
							</div> */}
						</div>
					</div>

					{/* <div className="col-12 col-md-5 py-1 py-md-1 h-75 mt-0 ml-1">
						<ContactInfo heading={props.heading} />
						<ContactForm displayMobile={props.displayMobile} />
					</div> */}
				</div>
			</div>
			<Footer />
		</>
	);
};

export default ContactUs;
