import React from 'react'
import NavbarComponent from '../Navigation/Navbar/NavbarComponent'


const Header = () => {
    return (
        <> 
         <NavbarComponent></NavbarComponent>
        </>
    )
}

export default Header
