import React from 'react'

const ContactInfo = (props) => {
    return (
        <>
            <h2 className="font-weight-bold text-center mb-1">
              {props.heading}
            </h2>

            <hr className="hr-sm my-6 my-md-8 border-gray-300"></hr>
        </>
    )
}

export default ContactInfo
