import React, { useEffect } from "react";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import { Link } from "react-router-dom";

const Pricing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getDetails = () => {
    return (
      <section className="pt-8 pt-md-11 pb-8 pb-md-14">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <h2 className="display-6 mb-1"><span className="text-primary">Pricing</span></h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr className="my-6 my-md-8" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-11">
              <p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
              Our standard pricing is between INR 20 to INR 30 per data fetch. However we also offer preferred and standard pricing based on volumes.  
              </p>
              <p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
              Please <Link className="ml-2" to="/contactus">Contact Us</Link> for further information and discussion.
              </p>



            </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <>
      <Header></Header>
      {getDetails()}
      <Footer></Footer>
    </>
  );
};

export default Pricing;
