import React from 'react';
import { Link } from 'react-router-dom';
const finLegal = [
	{ name: 'About Us', link: '/aboutus' },
	{ name: 'Pricing', link: '/pricing' },
	{ name: 'Annual Returns', link: '/returns' },
	{ name: 'Citizens Charter', link: '/charter' },
];

export const buildLegalList = () => {
	return finLegal.map((element, index) => {
		return (
			<li className="mb-0" key={index}>
				<Link to={element.link} className="text-reset">
					{element.name}
				</Link>
			</li>
		);
	});
};
