import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const FinApp = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openNewTab = (event) => {
    event.preventDefault();
    window.open(("_#", "https://finvu.github.io/sandbox/"));
  };

  const getFinAppDetails = () => {
    return (
      <section className="mt-10">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md">
            <h2>Finvu AA API</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-11">
            <p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
            Use account aggregation to get access to customer financial data and provide personalized experiences for your customers.
            The account aggregator APIs are offered as a service and are easy to integrate.
            </p>
            <p className="font-size-inherit text-gray-800 mb-6 mb-md-8"> 
            Get periodic access to customer financial data  with a single consent request. Multiple consent requests are not required 
            as the consent architecture is flexible and allows for periodic access to data with a single consent. 
            </p>
            <p className="font-size-inherit text-gray-800 mb-6 mb-md-8"> 
            Refer to our <a href="_#" className="ml-2" onClick={openNewTab}>API Documentation </a> or 
            <Link className="ml-2" to="/contactus"> Contact Us </Link> for more details  
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <hr className="my-6 my-md-8" />
        </div>
      </div>
    </section>
    );
  };

  return (
    <>
      {getFinAppDetails()}
    </>
  );
};

export default FinApp;
