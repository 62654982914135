import React, { useState, useEffect } from "react";
import { buildFinProductList } from "./../../../Constants/EntityList";
import axios from "axios";

const ContactForm = (props) => {
  const [contactUs, setContactUs] = useState({ entity: "NBFC" });
  const [error, setError] = useState({});
  const [success, setSuccess] = useState({ isSuccess: true, msg: "" });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props, error, success]);

  const handleValidation = () => {
    let fields = contactUs;
    let errors = {};
    let formIsValid = true;

    console.log(fields["name"]);

    if (typeof fields["mNumber"] !== "undefined") {
      if (
        !fields["mNumber"].match(/^[0-9]+$/) ||
        fields["mNumber"].length !== 10
      ) {
        formIsValid = false;
        errors["mNumber"] = "Invalid mobile number";
      }
    }

    //Name
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Please provide valid name";
    }

    if (typeof fields["name"] !== "undefined") {
      if (!fields["name"].match(/^[a-zA-Z, ]+$/)) {
        formIsValid = false;
        errors["name"] = "Please provide valid name";
      }
    }

    //Email
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Please provide valid Email Id";
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Please provide valid Email Id";
      }
    }

    setError(errors);
    return formIsValid;
  };

  const setValue = (event) => {
    event.preventDefault();
    setContactUs({ ...contactUs, [event.target.name]: event.target.value });
  };

  const onSubmitDb = (event) => {
    event.preventDefault();
    console.log(contactUs);
    if (handleValidation()) {
      axios
        .post("https://finvu.in/webapi/contactUs", contactUs)
        .then((response) => {
          setSuccess({ isSuccess: true, msg: response.data.responseDesc });
          setContactUs({});
        })
        .catch((error) => {
          setSuccess({ isSuccess: false, msg: "Error Sending Message" });
        });
    }
  };

  return (
    <>
      <form onSubmit={onSubmitDb} noValidate>
        {success.isSuccess ? (
          <div className="text-lg-center text-sm-center">
            <b><span className="text-success" style={{color:"#E7F3FE"}}>{success.msg}</span></b>
          </div>
        ) : (
          <div className="text-lg-center text-sm-center">
            <b><span className="text-danger">{success.msg}</span></b>
          </div>
        )}

        <div className="form-group mb-5">
          <label>Full name</label>

          <input
            type="text"
            className="form-control"
            id="contactName"
            name="name"
            placeholder="Full name"
            value={contactUs.name || ''}
            onChange={(event) => setValue(event)}
          />
          <span style={{ color: "red", fontSize: "12px" }}>
            {error["name"]}
          </span>
        </div>
        <div className="form-group mb-5">
          <label>Email</label>

          <input
            type="email"
            className="form-control"
            id="contactEmail"
            name="email"
            placeholder="hello@domain.com"
            value={contactUs.email || ''}
            onChange={(event) => setValue(event)}
          />
          <span style={{ color: "red", fontSize: "12px" }}>
            {error["email"]}
          </span>
        </div>

        {props.displayMobile ? (
          <div className="form-group mb-5">
            <label>Mobile Number</label>

            <input
              type="mobile"
              className="form-control"
              id="Mobile Number"
              name="mNumber"
              placeholder="Mobile"
              value={contactUs.mNumber || ''}
              onChange={(event) => setValue(event)}
            />
            <span style={{ color: "red", fontSize: "12px" }}>
              {error["mNumber"]}
            </span>
          </div>
        ) : null}

        <div className="form-group mb-5">
          <label>Are you an Individual or Select your entity</label>

          <select
            className="form-control"
            id="productSelect"
            name="entity"
            value={contactUs.entity || 'NBFC'}
            onChange={(event) => setValue(event)}
          >
            {buildFinProductList()}
          </select>
        </div>
        <div className="form-group mb-5">
          <label>What can we help you with?</label>

          <textarea
            className="form-control"
            id="contactMessage"
            rows="5"
            name="comment"
            placeholder="Tell us what we can help you with!"
            value={contactUs.comment || ''}
            onChange={(event) => setValue(event)}
          ></textarea>
        </div>
        <div className="form-group mb-0">
          <button
            type="submit"
            value="Send message"
            className="btn btn-block btn-primary lift"
          >
            Send message
          </button>
        </div>
      </form>
    </>
  );
};

export default ContactForm;
