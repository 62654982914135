import React from "react";

const Collections = () => {
  const getDetails = () => {
    return (
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <h2>Collections</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-11">
              <p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
                With customer consented periodic data access, loan monitoring
                solutions can be built to help in collections. Get early
                indicators on customer’s ability to re-pay loans by monitoring
                their accounts once loan is disbursed.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr className="my-6 my-md-8" />
          </div>
        </div>
      </section>
    );
  };

  return <>{getDetails()}</>;
};

export default Collections;
