import React, { useEffect } from "react";
import Header from "../Layout/Header/Header";
import Footer from "../Layout/Footer/Footer";
import Lendings from "./Lendings";
import SavingInvestment from "./SavingInvestment";
import PersonalFinance from "./PersonalFinance";
import Collections from "./Collections";
import { Link } from "react-router-dom";

const Solutions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header></Header>
      <section className="mt-10">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <h2><span className="text-primary">Building Use Cases</span></h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-11">
              <p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
                There are several use cases that can be built with the Account Aggregator ecosystem.  
                Few have been provided below as a reference and we are already working with institutions on these. 
                
              </p>
              <p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
              If you have an interesting use case and are ready to explore further do  
                 <Link className="ml-2" to="/contactus"> Reach out </Link> to us to help you build.
              </p>
            </div>
          </div>
        </div>
        <div className="row"> 
          <div className="col-12">
            <hr className="my-6 my-md-8" />
          </div>
        </div>
      </section>
      <Lendings />
      <PersonalFinance />
      <Collections />
      <SavingInvestment />

      <Footer></Footer>
    </>
  );
};

export default Solutions;
