import React from "react";
import image1 from "./../../assets/img/finvu-image/finvu_demo_video.mp4";
import image2 from "./../../assets/img/finvu-image/app_fiu_consent_request.svg";

const imagePlaceholder = [image1, image2];

const HowItWorksBody = (props) => {

  const openNewTab = (event) => {
    event.preventDefault();
    window.open(("_#", "https://finvu.github.io/sandbox/"));
  };

  const getPageDetails = (pageType) => {
    switch (pageType) {
      case "SECTION1":
        return (
          <div className="col-12 col-md-7 col-lg-6 order-md-1">
            <h2 className="display-6 text-center text-md-left">
              <span className="text-primary">For End Users</span>
            </h2>

            <p className="text-center text-md-left text-gray-800 mb-0 mb-lg-8">
              End users of the ecosystem can be individuals, proprietors etc who wish to share their information 
              for availing a financial service or product. 
              In a few steps you will be able to register and link your accounts for consented data sharing from the Finvu App.   
            </p>
            
            <h3 className="mb-1">Step 1. Register on Finvu App</h3>
            <p className="text-center text-md-left text-muted mb-0 mb-lg-8">
            Once you register you get a unique VUA (Virtual User Address) or an AA id e.g. you@finvu
            </p>
            <h3 className="mt-3 mb-1">Step 2. Link your Accounts</h3>
            <p className="text-center text-md-left text-muted mb-0 mb-lg-8">
            Linking your accounts in the Finvu App allows you to select from which accounts you wish to share information.
            No credentials are required and your accounts are authenticated by your financial institutions directly. 
            </p>
            <h3 className="mt-3 mb-1">Step 3. Provide Consent </h3>
            <p className="text-center text-md-left text-muted mb-0 mb-lg-8">
            Once your accounts are linked, you can now provide a consent to your financial information users from whom you wish to avail a service.
            You can also get a view of your aggregated balances and transactions for your accounts on the app. Consent approval is done on the Finvu App.
            </p>
          </div>
        );
      case "SECTION2":
        return (
          <div className="col-12 col-md-7 col-lg-6 order-md-1">
            <h2 className="display-6 text-center text-md-left">
              <span className="text-primary">For Financial Information Users</span>
            </h2>
            <p className="text-center text-md-left text-gray-800 mb-0 mb-lg-8">
              Financial Information Users can request customers for a consent to get their financial data for providing financial services or products.
            </p>
            <p className="text-center text-md-left text-gray-800 mb-0 mb-lg-8">
            Our integration APIs allow a seamless journey for your customers. See our 
            <a
                href="_#"
                className="ml-2"
                onClick={openNewTab}
              >
                API Documentation
              </a> for more information.   
            </p>
            <h3 className="mb-1">Step 1. Request for Consent</h3>
            <p className="text-center text-md-left text-muted mb-0 mb-lg-8">
            Initiate a consent request from your platform by asking customer for their Finvu VUA. 
            This can be a self service or an assisted operation.
            </p>
            <h3 className="mt-3 mb-1">Step 2. Get Consent from your Customer</h3>
            <p className="text-center text-md-left text-muted mb-0 mb-lg-8">
              Your customer receives a notification on the Finvu App for them to review and approve. 
              You are notified when the customer has actioned.
            </p>
            <h3 className="mt-3 mb-1">Step 3. Fetch Account Information in Real-time</h3>
            <p className="text-center text-md-left text-muted mb-0 mb-lg-8">
            Request for financial information on basis of the consent provided by your Customer over secure channel. 
            Data you receive can be analysed and used for the service that you are providing the customer.
            </p>
          </div>
        );
     
      default:
        break;
    }
  };
  
  return (
    <div
      className={props.styleParam}
      style={props.pageStyle === "ODD" ? { backgroundColor: props.bgcolor } : null}
    >
      <div className="container">
        {props.pageStyle === "EVEN" ? (
          <div className="row align-items-center">
            {getPageDetails(props.pageType)}
            {props.pageType === "SECTION3" ? null : (
              <div className="col-12 col-md-6 col-lg-6 order-md-0 ">

                  <video width="400" height="240" controls>
                    <source src={imagePlaceholder[props.index]} type="video/mp4"/>
                    Your browser does not support the video tag.
                  </video>

              </div>
            )}
          </div>
        ) : (
          <>
            <div className="row align-items-center">

              {props.pageType === "SECTION3" ? null : (

                <div className="col-12 col-md-5 col-lg-6 order-md-2 text-center">

                  <img
                  src={imagePlaceholder[props.index]}
                  className="img-thumbnail mw-md-10 mw-lg-10 mb-3 mb-md-0 border-0 img-fluid screenshot"
                  alt="..."
                />

                </div>
              )}

              {getPageDetails(props.pageType)}
            </div>
            <div className="row">
              <div className="col-12">
                <hr className="my-6 my-md-8 border-gray" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HowItWorksBody;
