import React, { useEffect } from 'react';
import Header from '../Layout/Header/Header';
import Footer from '../Layout/Footer/Footer';
import { Link } from 'react-router-dom';

const Learn = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const openNewTab = (event) => {
		event.preventDefault();
		window.open(
			('_#', 'https://rbi.org.in/Scripts/BS_ViewMasDirections.aspx?id=10598')
		);
	};

	const getDetails = () => {
		return (
			<section className="pt-8 pt-md-11 pb-8 pb-md-14">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-12 col-md">
							<h2 className="display-6 mb-1">
								<span className="text-primary">About Account Aggregator</span>
							</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<hr className="my-6 my-md-8" />
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-11">
							<p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
								NBFC Account Aggregators are RBI regulated entities that provide
								the infrastructure and platform for aggregating customer
								financial information on basis of a Consent. NBFC AA are the
								consent managers for customers sharing their financial data. The
								AA ecosystem is built on secure, interoperable APIs allowing
								customers, Account Aggregators (AA), Financial information
								providers (FIP), Financial information users (FIU) facilitate,
								exchange and access information in real time.
							</p>
							<h3 className="mb-5">
								Who are the data providers and data consumers
							</h3>
							<p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
								Entities holding Customer financial asset information can be the
								data providers as per current RBI Master guidelines or any other
								entities as prescribed by the regulator from time to time. Such
								entities are called Financial Information Providers (FIP). e.g.
								Banks, Insurance companies, Deposit taking NBFCs, Pension Funds,
								Mutual Funds (AMCs) etc.
							</p>
							<p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
								Any entities regulated by four financial sector regulators i.e.
								RBI, SEBI, IRDA, PFRDA can be data consumers or Financial
								Information Users (FIUs). These are as per the current master
								guidelines or may expand as prescribed by the regulator from
								time to time. e.g. NBFCs, Banks, Registered Investment advisors
								(RIA) regulated by SEBI, Pension Funds, Insurance companies,
								Stock Brokers (regulated by SEBI), Mutual funds etc.
							</p>
							<h3 className="mb-5">
								Is Finvu Account Aggregator for individual consumers only
							</h3>
							<p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
								No. Our ecosystem is built for not only individuals but also for
								enterprises.
							</p>
							<h3 className="mb-5">
								What kind of data can an FIU access through Finvu AA
							</h3>
							<p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
								Currently only asset based data is available (bank accounts,
								deposits, mutual funds, insurance policies, pension funds, etc).
								Other data types are likely to be added over time and are
								working with other data providers for new data sets.
								<Link className="ml-2" to="/contactus">
									Contact Us
								</Link>{' '}
								for more information.
							</p>
							<h3 className="mb-5">
								Can Finvu AA view your data as it is shared to FIUs
							</h3>
							<p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
								No. The data being transmitted through our platform is encrypted
								and hence we are not allowed to store or process the data.
							</p>
							<h3 className="mb-5">
								How is Finvu able to show balances and transactions on the app
							</h3>
							<p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
								We only present this information if you as a Customer wish to
								view your balance and transactions. It’s your choice to provide
								a consent to view your account information. All data is stored
								in an encrypted data store on your mobile device and we do not
								have access to it.
							</p>
							<h3 className="mb-5">
								We are an FIU and would like to leverage Finvu AA. How do we get
								started
							</h3>
							<p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
								Start with our API documentation and do
								<Link className="ml-2" to="/contactus">
									Contact Us
								</Link>{' '}
								for more information.
							</p>
							<h3 className="mb-5">
								What guidelines do Account Aggregators follow
							</h3>
							<p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
								Account Aggregators follow{' '}
								<a href="_#" className="ml-2" onClick={openNewTab}>
									RBI NBFC AA Master Directions{' '}
								</a>
							</p>
							For any further queries on the ecosystem reach out to us.
						</div>
					</div>
				</div>
			</section>
		);
	};

	return (
		<>
			<Header></Header>
			{getDetails()}
			<Footer></Footer>
		</>
	);
};

export default Learn;
