import React from "react";

const PersonalFinance = () => {
  const getDetails = () => {
    return (
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <h2>Personal Finance Management</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-11">
              <p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
                Help your customers take control of their finances by powering
                your apps with real time spend analysis, forecasting and budget
                information, and giving them complete picture of their financial health. 
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr className="my-6 my-md-8" />
          </div>
        </div>
      </section>
    );
  };

  return <>{getDetails()}</>;
};

export default PersonalFinance;
