import React, { useEffect } from "react";
import Header from "../Layout/Header/Header";
import Footer from "../Layout/Footer/Footer";
import { Link } from "react-router-dom";

const Security = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getDetails = () => {
    return (
      <div>
        <section className="pt-8 pt-md-11 pb-8 pb-md-14">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md">
                <h1 className="display-4 mb-0">Finvu Security</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <hr className="my-6 my-md-8" />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-11">
                <h3 className="mb-5">Privacy Practices</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    We do not sell your personal information to or share it with
                    unaffiliated third parties for their own advertising or
                    marketing purposes without your explicit consent
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    Check out our <Link to="/privacy">Privacy Policy </Link> for
                    more information  
                  </p>
                </div>

                <h3 className="mb-5">Service Infrastructure</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    FINVU is hosted on secure dedicated servers co-located in a
                    <b> Tier 3 Data Center </b> which provides a secure and
                    scalable technology platform to ensure we can provide you
                    services securely and reliably.
                  </p>
                </div>

                <h3 className="mb-5">Perimeter Security</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    We have a 3-Tier Architecture which incorporates best
                    practices from various standards and certifications
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    We have strict network segmentation and isolation of
                    environments and services in place.
                  </p>
                </div>

                <h3 className="mb-5">Host Security</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    We use industry leading solutions around anti-virus,
                    anti-malware, intrusion prevention systems, intrusion
                    detection systems, file integrity monitoring, application
                    control, application and audit log aggregation, and
                    automated patching
                  </p>
                </div>

                <h3 className="mb-5">Data Security</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    We employ separation of environments and segregation of
                    duties and have strict role-based access control on a
                    documented, authorized, need-to-use basis
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    We use key management services to limit access to data
                    except the data team
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    Stored data is protected by encryption at rest and sensitive
                    data by application level encryption
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    We use data replication for data resiliency, snapshotting
                    for data durability and backup/restore testing for data
                    reliability.
                  </p>
                </div>

                <h3 className="mb-5">Incident and Change Management</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    We have deployed mature processes around Change Management
                    which enables us to release thoroughly tested features for
                    you both reliably and securely.
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    We have an Information Security Management System in place
                    which quickly reacts, remediates or escalates any Incidents
                    arising out of planned or unplanned changes.
                  </p>
                </div>

                <h3 className="mb-5">
                  Vulnerability Assessment and Penetration Testing
                </h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    We employ both static application security testing and
                    dynamic application security testing which is incorporated
                    into our continuous integration / continuous deployment
                    pipeline.
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    We are certified by RBI empanelled CERT-IN auditors and who
                    will also conduct periodic external security testing and
                    audits.
                  </p>
                </div>

                <h3 className="mb-5">Standards and Certifications</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    We have successfully implemented RBI Master guidelines for
                    NBFC Account Aggregator and technical specifications.
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    We meet the “Data Localization” requirements as per Reserve
                    Bank of India(RBI) guidelines. This means all our customer
                    data securely resides inside data centers in India using
                    WebWerks
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    All compliance/audit statuses will be updated in this
                    section in this policy.
                  </p>
                </div>

                <h3 className="mb-5">Responsible Disclosure</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    We are committed about our customer's data and privacy.
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    The overall data and privacy security design allows us
                    defend our systems ranging from low hanging issue up to
                    sophisticated attacks.
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    If you are a security enthusiast or a researcher and you
                    have found a possible security vulnerability on FINVU
                    products, we encourage you to report the issue to us
                    responsibly.
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    You could submit a bug report to us at support@finvu.in with
                    detailed steps required to reproduce the vulnerability.
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-0 mr-4">
                    <i className="fe fe-chevrons-right"></i>
                  </span>

                  <p className="text-gray-800">
                    We shall put best of our efforts to investigate and fix the
                    legitimate issues in a reasonable time frame, meanwhile,
                    requesting you not to publicly disclose it.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  };

  return (
    <>
      <Header></Header>
      {getDetails()}
      <Footer></Footer>
    </>
  );
};

export default Security;
