import React, { useEffect } from 'react';
import Header from '../Layout/Header/Header';
import Footer from '../Layout/Footer/Footer';
// import FinConnectHub from './FinConnectHub';
// import Finsense from './Finsense';
import FinApp from './FinApp';

const Products = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<Header></Header>
			<section className="mt-10">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-12 col-md">
							<h3 className="display-4 text-center text-md-left">
								<span className="text-primary">Building Blocks </span>for
								financial services
							</h3>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-11">
							<p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
								We offer products for Financial Information Users and Financial
								Information Providers that can be used individually to provide
								better customer experiences or all together for a digital
								transformation.
							</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<hr className="my-6 my-md-8" />
					</div>
				</div>
			</section>
			<FinApp />
			{/* <FinConnectHub /> */}
			{/* <Finsense /> */}

			<Footer></Footer>
		</>
	);
};

export default Products;
