import React, { useEffect } from "react";
import Header from "../Layout/Header/Header";
import Footer from "../Layout/Footer/Footer";
import MainBody from "../Layout/MainBody/MainBody";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header></Header>
      <MainBody pageType="SECTION1" pageStyle="EVEN" index={0} styleParam="pt-4 pt-md-10"></MainBody>
      <MainBody pageType="SECTION2" pageStyle="ODD" index={1} styleParam="pt-4 pt-md-5"></MainBody>
      <MainBody pageType="SECTION3" pageStyle="EVEN" index={2} styleParam="pt-4 pt-md-10"></MainBody>
      <MainBody pageType="SECTION4" pageStyle="ODD" index={3} bgcolor="#E7F3FE" styleParam="pt-4 pt-md-5"></MainBody>
      <MainBody pageType="SECTION5" pageStyle="EVEN" index={4} styleParam="pt-4 pt-md-10"></MainBody>

      <Footer></Footer>
    </div>
  );
};

export default Home;
