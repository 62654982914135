import React, { useState } from "react";
import { Link } from "react-router-dom";


const getMenu = (menuList) => {
  var info = Object.values(menuList).map((product) => {
    //const childProduct = productInfoList.get(product);

    return (
      <div key={product.name}>
        {product.values.map((element, index) => {
          return (
            <Link
              key={index}
              className={element.classes}
              to={element.pointingTo}
            >
              {element.name}
            </Link>
          );
        })}
      </div>
    );
  });
  return info;
};

const HeadingMenu = (props) => {
  const [openDevMenu, setDevMenu] = useState(false);

  const toggleMenu = () => {
    setDevMenu(!openDevMenu);
  };


  const show = openDevMenu ? "show" : "";
  console.log(show);

  return (
      
      <li className={"nav-item dropdown " + show}  onMouseOut={toggleMenu}>
                <Link
                  className="nav-link dropdown-toggle"
                  id="navbarPages"
                  data-toggle="dropdown"
                  to="/"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onMouseOver={toggleMenu}
                >
                  {props.heading}
                </Link>
                <div className="dropdown-menu dropdown-menu-md">
                  <div className="row no-gutters">
                    <div className="col-10">
                      <div className="row no-gutters">
                        <div className="col-12 col-lg-6">
                             <div className="col-12 col-lg-6" >
                                {getMenu(props.payload)}
                            </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </li>

);
};

export default HeadingMenu;
