import React from 'react';
import { Link } from 'react-router-dom';

const informationList = [
	{ name: 'Privacy', link: '/privacy' },
	{ name: 'Security', link: '/security' },
];

export const buildInformation = () => {
	return informationList.map((element, index) => {
		return (
			<li className="mb-0" key={index}>
				<Link to={element.link} className="text-reset">
					{element.name}
				</Link>
			</li>
		);
	});
};
