import React, { useState } from 'react';
import logo from './../../../../assets/img/finvu-image/brand.png';
import { Nav, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeadingMenu from '../Menu/HeadingMenu/HeadingMenu';
import { resourcesMenuList } from './../../../Constants/MenuItems';

const NavbarComponent = () => {
	const [toggel, setToggel] = useState(false);

	const toggleMenu = () => {
		setToggel(!toggel);
	};

	const openNewTab = (event) => {
		event.preventDefault();
		window.open(('_#', 'https://finvu.github.io/sandbox/'));
	};

	const show = toggel ? 'show' : '';

	return (
		<Nav className="navbar navbar-expand-lg navbar-light bg-white">
			<div className="container">
				<Link className="navbar-brand" to="/">
					<Image className="navbar-brand-img" src={logo} alt="..."></Image>
				</Link>

				<Button className="navbar-toggler" onClick={toggleMenu}>
					<span className="navbar-toggler-icon"></span>
				</Button>

				<div className={'collapse navbar-collapse ' + show} id="navbarCollapse">
					<Button className="navbar-toggler" onClick={toggleMenu}>
						<i className="fe fe-x"></i>
					</Button>

					<ul className="navbar-nav ml-auto">
						<Link to="/products" className="nav-link">
							Products
						</Link>
						{/*<HeadingMenu
                heading="Products"
                payload={productMenuList}
              ></HeadingMenu>*/}
						{/*<HeadingMenu
                heading="How It Works"
                payload={howItWorksMenuList}
              ></HeadingMenu>*/}
						{/*<HeadingMenu
                heading="Solutions"
                payload={solutionsMenuList}
              ></HeadingMenu>*/}
						<Link to="/solutions" className="nav-link">
							Use Cases
						</Link>
						<HeadingMenu
							heading="Resources"
							payload={resourcesMenuList}
						></HeadingMenu>

						<Link to="/howitworks" className="nav-link">
							How It Works
						</Link>

						{/**<HeadingMenu heading="About Us" payload={aboutUsMenuList}></HeadingMenu>**/}
						{/**<HeadingMenu heading="For Developers" payload={developerMenuList}></HeadingMenu>**/}
						<div className="mt-0">
							<a
								href="_#"
								className="btn btn-primary btn-sm shadow mr-5 lift"
								onClick={openNewTab}
							>
								For Developers
							</a>
							{/* <Link
								to="/partnerwithus"
								className="btn btn-primary-desat-soft btn-sm lift"
							>
								Become A Partner
							</Link> */}
						</div>
					</ul>
				</div>
			</div>
		</Nav>
	);
};

export default NavbarComponent;
