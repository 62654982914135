import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import brand from './../../../assets/img/finvu-image/brand.png';
import { buildFinProductList } from './../../Constants/Product';
import { buildLegalList } from './../../Constants/Legal';
import { buildConnect } from './../../Constants/Connect';
import googleplay from './../../../assets/img/finvu-image/google.png';
import appstore from './../../../assets/img/finvu-image/apple.png';
import { buildInformation } from '../../Constants/Information';

const openNewGooglePlayStoreTab = (event) => {
	event.preventDefault();
	window.open(
		('_#', 'https://play.google.com/store/apps/details?id=com.finvu')
	);
};

const openNewAppStoreTab = (event) => {
	event.preventDefault();
	window.open(
		('_#', 'https://apps.apple.com/in/app/finvu/id6499302887')
	);
};

const openNewTermsTab = (event) => {
	event.preventDefault();
	window.open(
		'/terms',
		'_blank',
		'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=700,height=700',
		false
	);
};

const Footer = () => {
	return (
		<footer className="py-8 py-md-11 bg-gray-200 mt-8">
			<div className="container">
				<div className="row">
					<div className="col-12 col-md-4 col-lg-4">
						<Link className="navbar-brand" to="/">
							<Image className="navbar-brand-img" src={brand} alt="..."></Image>
						</Link>

						<p className="text-gray-700 mb-1" style={{ fontSize: '12px' }}>
							Cookiejar Technologies Pvt Ltd. is a registered and licensed NBFC
							Account Aggregator (NBFC-AA) from the Reserve Bank of India (RBI)
							and has the operational license for the business of account
							aggregation.
						</p>

						<ul className="list-unstyled list-inline list-social mb-6 mb-md-0">
							<Link className="navbar-brand" to="_#" onClick={openNewGooglePlayStoreTab}>
								<Image
									className="navbar-brand-img"
									src={googleplay}
									alt="..."
								></Image>
							</Link>
							<Link className="navbar-brand" to="_#" onClick={openNewAppStoreTab}>
								<Image
									className="navbar-brand-img"
									src={appstore}
									alt="..."
								></Image>
							</Link>
						</ul>
						<p className="text-gray-700 mb-1" style={{ fontSize: '10px' }}>
							Google Play and the Google Play logo are trademarks of Google LLC.
						</p>
					</div>
					<div className="col-6 col-md-4 col-lg-2">
						<h6 className="font-weight-bold text-uppercase text-gray-700">
							Products
						</h6>

						<ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
							{buildFinProductList()}
						</ul>
					</div>

					<div className="col-6 col-md-4 col-lg-2">
						<h6 className="font-weight-bold text-uppercase text-gray-700">
							POLICIES
						</h6>

						<ul className="list-unstyled text-muted mb-0">
							{buildInformation()}
						</ul>
						<ul className="list-unstyled text-muted mb-0">
							<li className="mb-0">
								<Link to="_#" onClick={openNewTermsTab} className="text-reset">
									Terms and Conditions
								</Link>
							</li>
						</ul>
					</div>
					<div className="col-6 col-md-4 col-lg-2">
						<h6 className="font-weight-bold text-uppercase text-gray-700">
							COMPANY
						</h6>

						<ul className="list-unstyled text-muted mb-0">
							{buildLegalList()}
						</ul>
					</div>

					<div className="col-6 col-md-4 col-lg-2">
						<h6 className="font-weight-bold text-uppercase text-gray-700">
							CONNECT
						</h6>

						<ul className="list-unstyled text-muted mb-0">{buildConnect()}</ul>
					</div>
				</div>
			</div>
			<div className="row text-sm-center text-gray-700">
				<div className="col-12 col-md-12 col-lg-12 ">
					<p style={{ fontSize: '13px' }}>
						&copy; 2023 Finvu is a brand of Cookiejar Technologies Pvt Ltd
					</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
