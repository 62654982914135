import React, { useEffect } from "react";
import Header from "../Layout/Header/Header";
import Footer from "../Layout/Footer/Footer";
import HowItWorksBody from "../FinvuHowItWorks/HowItWorksBody";


const HowItWorks = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header></Header>
      <HowItWorksBody pageType="SECTION1" pageStyle="EVEN" index={0} styleParam="pt-4 pt-md-10"></HowItWorksBody>
      <HowItWorksBody pageType="SECTION2" pageStyle="ODD" index={1} styleParam="pt-4 pt-md-5"></HowItWorksBody>
      <Footer></Footer>
    </div>
  );
};

export default HowItWorks;
