import React from 'react';
import image1 from './../../../assets/img/finvu-image/splash_screen_graphic.svg';
import image3 from './../../../assets/img/finvu-image/building_block.svg';
import image2 from './../../../assets/img/finvu-image/graphic_consent_03.svg';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import googleplay from './../../../assets/img/finvu-image/google.png';
import appstore from './../../../assets/img/finvu-image/apple.png';
import axisbanklogo from './../../../assets/img/finvu-image/axis_bank_logo.png';
import icicibanklogo from './../../../assets/img/finvu-image/icici_bank_logo.png';
import hdfcbanklogo from './../../../assets/img/finvu-image/hdfc_bank_logo.png';
import federalbanklogo from './../../../assets/img/finvu-image/federal_bank_logo.png';
import indusindbanklogo from './../../../assets/img/finvu-image/indusind_bank_logo.jpeg';
import dmifinancelogo from './../../../assets/img/finvu-image/dmifinance_logo.png';
import lendingkartlogo from './../../../assets/img/finvu-image/lendingkart_logo.png';
import neogrowthlogo from './../../../assets/img/finvu-image/neogrowth_logo.svg';
import kairoscapitallogo from './../../../assets/img/finvu-image/kairoscapital_logo.png';
import idfcfirstbanklogo from './../../../assets/img/finvu-image/idfcfirstbank_logo.jpg';
import kotakbanklogo from './../../../assets/img/finvu-image/kotakbank_logo.png';
import paisabazaarlogo from './../../../assets/img/finvu-image/paisabazaar_logo.png';

const imagePlaceholder = [image1, image2, image3, ''];

const MainBody = (props) => {
	const openNewTab = (event) => {
		event.preventDefault();
		window.open(('_#', 'https://finvu.github.io/sandbox/'));
	};

	const openNewPlayStoreTab = (event) => {
		event.preventDefault();
		window.open(
			('_#', 'https://play.google.com/store/apps/details?id=com.finvu')
		);
	};

	const openNewAppStoreTab = (event) => {
		event.preventDefault();
		window.open(
			('_#', 'https://apps.apple.com/in/app/finvu/id6499302887')
		);
	};

	const getPageDetails = (pageType) => {
		switch (pageType) {
			case 'SECTION1':
				return (
					<div className="col-12 col-md-7 col-lg-6 order-md-1">
						<h3 className="display-4 text-center text-md-left">
							Now, Access to Finances is{' '}
							<span className="text-primary">Simple and Secure.</span>
						</h3>

						<p className="text-center text-md-left text-muted mb-0 mb-lg-8">
							Sharing information is easy, secure and in real time. Customers
							have a trusted platform. Introducing, Finvu Account Aggregator.
						</p>
						<p className="text-center text-md-left text-body mb-3 mb-lg-8">
							Cookiejar Technologies Pvt Ltd (brand name Finvu) is an RBI
							licensed NBFC Account Aggregator in India. We are providing the
							platform that enables secure sharing of trusted data between
							financial institutions.
							<br />
							<a href="https://aaweb.finvu.in">Sign up</a>
						</p>
					</div>
				);
			case 'SECTION2':
				return (
					<div className="col-12 col-md-6 col-lg-6 order-md-1">
						<h3 className="display-4 text-center text-md-left">
							Consent Based data sharing using our{' '}
							<span className="text-primary">Trusted Platform.</span>
						</h3>
						<p className="text-center text-md-left text-muted mb-2 mb-lg-8">
							Finvu provides the platform to facilitate sharing of information
							between your financial institution and the financial information
							users who are provinding you financial services.
						</p>
						<div className="d-flex">
							<span className="badge badge-rounded-circle badge-success-soft mt-1 ml-4">
								<i className="fe fe-check"></i>
							</span>
							<p className="text-muted ml-3">
								Real time sharing of information with your Consent.
							</p>
						</div>
						<div className="d-flex">
							<span className="badge badge-rounded-circle badge-success-soft mt-1 ml-4">
								<i className="fe fe-check"></i>
							</span>
							<p className="text-muted ml-3">
								Information is shared securely as data is encrypted at rest and
								in transit.
							</p>
						</div>
						<div className="d-flex">
							<span className="badge badge-rounded-circle badge-success-soft mt-1 ml-4">
								<i className="fe fe-check"></i>
							</span>
							<p className="text-muted ml-3">
								No credentials of your accounts held with financial institution
								are taken or stored by us.
							</p>
						</div>
						<div className="d-flex">
							<span className="badge badge-rounded-circle badge-success-soft mt-1 ml-4">
								<i className="fe fe-check"></i>
							</span>
							<p className="text-muted ml-3">
								Manage your consents on the Finvu app and more.
							</p>
						</div>
						<p className="text-center text-md-left text-muted mb-2 mb-lg-8">
							Download the Finvu App from Playstore, Appstore or Use our web application.
						</p>
						<div className="store d-flex">
						<Link
							className="navbar-brand playstore"
							to="_#"
							style={{display: 'inline-flex', justifyContent: 'center'}}
							onClick={openNewPlayStoreTab}
						>
							<Image
								className="img-thumbnail mw-md-35 mb-6 mb-md-0 border-0"
								src={googleplay}
								alt="..."
							></Image>
						</Link>
						<Link
							className="navbar-brand" 
							to="_#"
							onClick={openNewAppStoreTab}
						>
							<Image
								className="img-thumbnail mw-md-35 mb-6 mb-md-0 border-0"
								src={appstore}
								alt="..."
							></Image>
						</Link>
						</div>
					</div>
				);

			case 'SECTION3':
				return (
					<div className="col-12 col-md-7 col-lg-6 order-md-1 mr-0">
						<h3 className="display-4 text-center text-md-left">
							Be <span className="text-primary">Future Ready</span> for offering
							financial services
						</h3>
						<p className="text-center text-md-left text-muted mb-0 mb-lg-8">
							We are also helping institutions collaborate on the AA ecosystem
							and build future ready digital solutions to provide their
							customers with better experiences.
						</p>
						<p className="text-center text-md-left text-muted mb-3 mb-lg-8">
							Find out more by visiting our
							<Link to="/finapp" className="ml-2">
								Products
							</Link>
						</p>
					</div>
				);
			case 'SECTION4':
				return (
					<div className="col-12 col-md-12 col-lg-12">
						<h3 className="display-4 text-center text-md-left">
							Build products <span className="text-primary">using our API</span>
						</h3>
						<p className="text-center text-md-left text-muted ">
							We are providing an API driven infrastructure for financial data
							sharing which will be the core building block for digital economy.
						</p>
						<p className="text-center text-md-left text-muted mb-2 mb-lg-4">
							Start building now. See the API documentation to know more or{' '}
							<Link className="ml-2" to="/contactus">
								Reach Out
							</Link>{' '}
							to us.
						</p>
						<div className="text-center text-md-left mb-8 mb-lg-8">
							<a
								href="_#"
								className="btn btn-primary btn-sm lift"
								onClick={openNewTab}
							>
								API Documentation
							</a>
						</div>
					</div>
				);
			// case 'SECTION5':
			// 	return (
			// 		<div className="col-12 col-md-12 col-lg-12">
			// 			<h2 className="display-6 mb-1">
			// 				<span className="text-primary">Our Early adopters</span>
			// 			</h2>
			// 			<p></p>
			// 			<h3 className="mb-5">Banks and NBFCs </h3>
			// 			<p>
			// 				<Image
			// 					className="img-thumbnail mw-sm-5 mb-6 mb-md-0 border-0"
			// 					src={axisbanklogo}
			// 					alt="..."
			// 				></Image>
			// 				<Image
			// 					className="img-thumbnail mw-sm-10 mb-6 mb-md-0 border-0 mx-md-4"
			// 					src={federalbanklogo}
			// 					alt="..."
			// 				></Image>
			// 				<Image
			// 					className="img-thumbnail mw-sm-10 mb-6 mb-md-0 border-0 mx-md-4"
			// 					src={hdfcbanklogo}
			// 					alt="..."
			// 				></Image>
			// 				<Image
			// 					className="img-thumbnail mw-sm-10 mb-6 mb-md-0 border-0 mx-md-4"
			// 					src={icicibanklogo}
			// 					alt="..."
			// 				></Image>
			// 				<Image
			// 					className="img-thumbnail mw-sm-5 mb-6 mb-md-0 border-0 mx-md-4"
			// 					src={indusindbanklogo}
			// 					alt="..."
			// 				></Image>
			// 				<Image
			// 					className="img-thumbnail mw-sm-5 mb-6 mb-md-0 border-0 mx-md-4"
			// 					src={idfcfirstbanklogo}
			// 					alt="..."
			// 				></Image>
			// 				<Image
			// 					className="img-thumbnail mw-sm-5 mb-6 mb-md-0 border-0 mx-md-4"
			// 					src={kotakbanklogo}
			// 					alt="..."
			// 				></Image>
			// 			</p>
			// 			<p>
			// 				<Image
			// 					className="img-thumbnail mw-sm-10 mb-6 mb-md-0 border-0 mx-md-4"
			// 					src={dmifinancelogo}
			// 					alt="..."
			// 				></Image>
			// 				<Image
			// 					className="img-thumbnail mw-sm-10 mb-6 mb-md-0 border-0 mx-md-4"
			// 					src={lendingkartlogo}
			// 					alt="..."
			// 				></Image>
			// 				<Image
			// 					className="img-thumbnail mw-sm-10 mb-6 mb-md-0 border-0 mx-md-4"
			// 					src={neogrowthlogo}
			// 					alt="..."
			// 				></Image>
			// 			</p>
			// 			<h3 className="mb-5">SEBI Regulated RIAs</h3>
			// 			<p>
			// 				<Image
			// 					className="img-thumbnail mw-sm-10 mb-6 mb-md-0 border-0"
			// 					src={kairoscapitallogo}
			// 					alt="..."
			// 				></Image>
			// 				<Image
			// 					className="img-thumbnail mw-sm-10 mb-6 mb-md-0 border-0"
			// 					src={paisabazaarlogo}
			// 					alt="..."
			// 				></Image>
			// 			</p>
			// 		</div>
			// 	);
			default:
				break;
		}
	};
	console.log(props.pageType);
	return (
		<div
			className={props.styleParam}
			style={
				props.pageStyle === 'ODD' ? { backgroundColor: props.bgcolor } : null
			}
		>
			<div className="container">
				{props.pageStyle === 'ODD' ? (
					<div className="row align-items-center">
						{getPageDetails(props.pageType)}
						{console.log('inside first loop')}
						{props.pageType === 'SECTION4' ||
						props.pageType === 'SECTION5' ? null : (
							<div className="col-12 col-md-6 col-lg-6 order-md-0 ">
								<img
									src={imagePlaceholder[props.index]}
									className="img-thumbnail mw-md-10 mw-lg-10 mb-3 mb-md-0 border-0"
									alt="..."
								/>
							</div>
						)}
					</div>
				) : (
					<>
						<div className="row align-items-center">
							{console.log('inside second loop')}
							{props.pageType === 'SECTION4' ||
							props.pageType === 'SECTION5' ? null : (
								<div className="col-12 col-md-5 col-lg-6 order-md-2 text-center">
									<img
										src={imagePlaceholder[props.index]}
										className="img-thumbnail mw-md-10 mw-lg-10 mb-6 mb-md-0 border-0"
										alt="..."
									/>
								</div>
							)}

							{getPageDetails(props.pageType)}
						</div>
						<div className="row">
							<div className="col-12">
								<hr className="my-6 my-md-8 border-gray" />
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default MainBody;
