export const developerMenuList = {
	Solution: {
		name: 'API Docs',
		values: [
			{
				name: 'API Docs',
				pointingTo: './careers.html',
				classes: 'dropdown-item',
			},
		],
	},
};

export const productMenuList = {
	Solution: {
		name: 'Products',
		values: [
			{
				name: 'Finvu App',
				pointingTo: '/finapp',
				classes: 'dropdown-item',
			},
			// {
			//   name: "Finvu ConnectHub",
			//   pointingTo: "/finconnecthub",
			//   classes: "dropdown-item",
			// },
			// {
			//   name: "Finvu Finsese",
			//   pointingTo: "/finsense",
			//   classes: "dropdown-item",
			// },
		],
	},
};

export const howItWorksMenuList = {
	Solution: {
		name: 'Products',
		values: [],
	},
};

export const solutionsMenuList = {
	Solution: {
		name: 'Lendings',
		values: [
			{
				name: 'Lending',
				pointingTo: '/lendings',
				classes: 'dropdown-item',
			},
			{
				name: 'Personal Finance Management',
				pointingTo: '/personalfinance',
				classes: 'dropdown-item',
			},
			{
				name: 'Flexible Saving and Investment',
				pointingTo: '/savinginvestment',
				classes: 'dropdown-item',
			},
		],
	},
};

export const resourcesMenuList = {
	Solution: {
		name: 'Resources',
		values: [
			{
				name: 'Learn',
				pointingTo: '/learn',
				classes: 'dropdown-item',
			},
			{
				name: 'Blog',
				pointingTo: '/blog',
				classes: 'dropdown-item',
			},
		],
	},
};

export const aboutUsMenuList = {
	Solution: {
		name: 'About Us',
		values: [
			{
				name: 'Company Profile',
				pointingTo: './aboutUs',
				classes: 'dropdown-item',
			},
		],
	},
};
