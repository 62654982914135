import React from 'react';
import { Link } from 'react-router-dom';
const finProductList = [
	{ name: 'Finvu AA API', linkto: '/products' },
	// { name: "Finvu ConnectHub", linkto: "/products" },
	// { name: "Finvu Finsense", linkto: "/products" }
];

export const buildFinProductList = () => {
	return finProductList.map((element, index) => {
		return (
			<li className="mb-0" key={index}>
				<Link to={element.linkto} className="text-reset">
					{element.name}
				</Link>
			</li>
		);
	});
};
