import React from 'react';
import { Link } from 'react-router-dom';

const connectList = [
	{ name: 'Contact Us', link: '/contactus' },
	{ name: 'Helpdesk', link: '/helpdesk' },
	{ name: 'Grievance', link: '/grievance' },
	{ name: 'Nodal Officer', link: '/nodal-officer' },
];

export const buildConnect = () => {
	return connectList.map((element, index) => {
		return (
			<li className="mb-0" key={index}>
				<Link to={element.link} className="text-reset">
					{element.name}
				</Link>
			</li>
		);
	});
};
