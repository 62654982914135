import React, { useEffect } from "react";
import Header from "../Layout/Header/Header";
import Footer from "../Layout/Footer/Footer";

const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getDetails = () => {
    return (
      <section className="pt-8 pt-md-11 pb-8 pb-md-14">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <h3 className="display-4 mb-1">Blog</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr className="my-6 my-md-8" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-11">
              <p className="font-size-lg text-gray-800 mb-6 mb-md-8">
                Publishing Soon !.
              </p>

             </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <>
      <Header></Header>
      {getDetails()}
      <Footer></Footer>
    </>
  );
};

export default Blog;
