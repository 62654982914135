import React from "react";

const SavingInvestment = () => {
  const getDetails = () => {
    return (
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <h2>Wealth Management</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-11">
              <p className="font-size-inherit text-gray-800 mb-6 mb-md-8">
                With holistic view of your customer’s financial portfolio, build
                wealth management solutions for their tailored needs. Reduce the
                manual paperwork and monthly maintenance of excels to track your
                customers portfolio
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr className="my-6 my-md-8" />
          </div>
        </div>
      </section>
    );
  };

  return <>{getDetails()}</>;
};

export default SavingInvestment;
